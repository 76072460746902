import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Carousel = ({ slides, slideTransitionDuration, currentSlide }) => (
  <S.Container>
    <S.SlidesContainer
      $slideTransitionDuration={slideTransitionDuration}
      $currentSlide={currentSlide}
    >
      {slides.map((slide, i) => (
        <S.Slide
          key={`slide-effect-${i + 1}`}
          $slideTransitionDuration={slideTransitionDuration}
          $isCurrentSlide={currentSlide === i + 1}
        >
          {slide}
        </S.Slide>
      ))}
    </S.SlidesContainer>
  </S.Container>
);

const S = {
  Container: styled.div`
    overflow-x: hidden;
  `,

  SlidesContainer: styled.div`
    display: flex;
    transform: translateX(
      ${({ $currentSlide }) => `calc(-${100 * ($currentSlide - 1)}%)`}
    );
    transition: transform
      ${({ $slideTransitionDuration }) => $slideTransitionDuration} ease-in-out;
  `,

  Slide: styled.div`
    flex: 0 0 100%;
    transition: opacity
      ${({ $slideTransitionDuration }) => $slideTransitionDuration} linear;

    ${({ $isCurrentSlide }) =>
      !$isCurrentSlide &&
      css`
        opacity: 0;
      `}
  `,
};

Carousel.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  slideTransitionDuration: PropTypes.string.isRequired,
  currentSlide: PropTypes.number.isRequired,
};

export default Carousel;
