import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Progress = ({ slidesLength, slideTransitionDuration, currentSlide }) => (
  <S.Container>
    <S.BorderContainer>
      <S.Text>
        Pergunta {currentSlide} de {slidesLength}
      </S.Text>
      <S.Bullets>
        {[...Array(slidesLength).keys()].map((i) => (
          <S.Bullet
            key={`bullet-${i + 1}}`}
            $isActive={currentSlide >= i + 1}
            $slideTransitionDuration={slideTransitionDuration}
          />
        ))}
      </S.Bullets>
    </S.BorderContainer>
  </S.Container>
);

const S = {
  Container: styled.div`
    margin: 0 auto;
    margin-top: 24px;
    padding: var(--grid-padding, 0 32px);
    max-width: var(--grid-width, 1100px);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 40px;
    }
  `,

  BorderContainer: styled.div`
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc;
  `,

  Text: styled.p`
    font-size: 10px;
    line-height: 11.5px;
    color: var(--color-text, #7f7f7f);
    text-transform: uppercase;
  `,

  Bullets: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > *:not(:last-of-type) {
      margin-right: 10px;
    }
  `,

  Bullet: styled.div`
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: ${({ $isActive }) =>
      $isActive ? 'var(--color-text, #7f7f7f)' : '#cccccc'};
    transition: background-color
      ${({ $slideTransitionDuration }) => $slideTransitionDuration} linear;
  `,
};

Progress.propTypes = {
  slidesLength: PropTypes.number.isRequired,
  slideTransitionDuration: PropTypes.string.isRequired,
  currentSlide: PropTypes.number.isRequired,
};

export default Progress;
