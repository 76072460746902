import React, { useState } from 'react';
import styled from 'styled-components';

import Layout from '../../common/Layout/Layout';
import Seo, { GRECAPTCHA_KEY } from '../../common/Seo/Seo';
import Progress from './Progress/Progress';
import Question from './Question/Question';
import Carousel from '../../common/Carousel/Carousel';
import FormSent from './FormSent/FormSent';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const capitalizeFirstLetter = (s) =>
  `${s.charAt(0).toUpperCase()}${s.slice(1)}`;

const isNextButtonDisabled = (formAnswers, inputName) =>
  formAnswers[inputName].hasError ||
  (formAnswers[inputName].isRequired && !formAnswers[inputName].wasTouched);

const intialFormAnswers = {
  name: {
    value: '',
    hasError: false,
    wasTouched: false,
    isRequired: true,
  },
  email: {
    value: '',
    hasError: false,
    wasTouched: false,
    isRequired: true,
    validationRegex: EMAIL_REGEX,
  },
  subject: {
    value: '',
    hasError: false,
    wasTouched: false,
    isRequired: true,
  },
  urgency: {
    value: '',
    hasError: false,
    wasTouched: false,
    isRequired: true,
  },
  type: {
    value: '',
    hasError: false,
    wasTouched: false,
    isRequired: true,
  },
};

const Contact = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [formAnswers, setFormAnswers] = useState(intialFormAnswers);
  const [isFormSent, setIsFormSent] = useState(false);

  const handleInputChange = (e) => {
    const targetValue = e.target.value.trim();
    const targetName = e.target.name;

    setFormAnswers((prevState) => ({
      ...prevState,
      [targetName]: {
        ...prevState[targetName],
        value: targetValue,
        wasTouched: true,
      },
    }));

    if (formAnswers[targetName].isRequired) {
      setFormAnswers((prevState) => ({
        ...prevState,
        [targetName]: {
          ...prevState[targetName],
          hasError: Boolean(!targetValue),
        },
      }));
    }

    if (formAnswers[targetName].validationRegex) {
      setFormAnswers((prevState) => ({
        ...prevState,
        [targetName]: {
          ...prevState[targetName],
          hasError: !formAnswers[targetName].validationRegex.test(targetValue),
        },
      }));
    }
  };

  const handleInputEnterPress = (e) =>
    e.key === 'Enter' &&
    !isNextButtonDisabled(formAnswers, e.target.name) &&
    setCurrentSlide((prevState) => prevState + 1);

  const handleRadioEnterPress = (e) => e.key === 'Enter' && e.target.click();

  const handleSubmit = (e) => {
    e.preventDefault();

    /* eslint-disable no-undef */
    grecaptcha.ready(() => {
      grecaptcha.execute(GRECAPTCHA_KEY, { action: 'submit' }).then((_) => {
        fetch('/mail.php', {
          method: 'POST',
          body: JSON.stringify({
            name: formAnswers.name.value,
            email: formAnswers.email.value,
            subject: formAnswers.subject.value,
            urgency: formAnswers.urgency.value,
            type: formAnswers.type.value,
          }),
        });

        setIsFormSent(true);
      });
    });
  };
  /* eslint-enable no-undef */

  const handlePrevSlide = (isFirstSlide = false) =>
    !isFirstSlide && setCurrentSlide((prevState) => prevState - 1);

  const handleNextSlide = (isLastSlide = false) =>
    !isLastSlide && setCurrentSlide((prevState) => prevState + 1);

  const slides = [
    <Question
      isCurrent={currentSlide === 1}
      text={
        <label htmlFor="name">
          oi! queremos conhecer você melhor. qual é o seu nome?
        </label>
      }
      handleBackButton={handlePrevSlide}
      handleContinueButton={handleNextSlide}
      isNextButtonDisabled={isNextButtonDisabled(formAnswers, 'name')}
      isFirstSlide
    >
      <S.InputContainer>
        <input
          type="text"
          name="name"
          placeholder="Escreva seu nome aqui"
          maxLength={15}
          disabled={currentSlide !== 1}
          onChange={handleInputChange}
          onKeyPress={handleInputEnterPress}
        />
      </S.InputContainer>
    </Question>,

    <Question
      isCurrent={currentSlide === 2}
      text={
        <label htmlFor="email">
          {formAnswers.name.value
            ? `${capitalizeFirstLetter(formAnswers.name.value)}, `
            : ''}
          é um prazer te conhecer. qual o seu email?
        </label>
      }
      handleBackButton={handlePrevSlide}
      handleContinueButton={handleNextSlide}
      isNextButtonDisabled={isNextButtonDisabled(formAnswers, 'email')}
    >
      <S.InputContainer>
        <input
          type="email"
          name="email"
          placeholder="Escreva seu melhor email"
          disabled={currentSlide !== 2}
          onChange={handleInputChange}
          onKeyPress={handleInputEnterPress}
        />
      </S.InputContainer>
    </Question>,

    <Question
      isCurrent={currentSlide === 3}
      text={
        <label htmlFor="subject">certo! e sobre o quê você quer falar?</label>
      }
      handleBackButton={handlePrevSlide}
      handleContinueButton={handleNextSlide}
      isNextButtonDisabled={isNextButtonDisabled(formAnswers, 'subject')}
    >
      <S.InputContainer>
        <S.RadioLabel
          tabIndex={currentSlide === 3 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="subject"
            disabled={currentSlide !== 3}
            value="project"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Meu próximo grande projeto</span>
        </S.RadioLabel>

        <S.RadioLabel
          tabIndex={currentSlide === 3 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="subject"
            disabled={currentSlide !== 3}
            value="cofee"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Quero marcar um café</span>
        </S.RadioLabel>

        <S.RadioLabel
          tabIndex={currentSlide === 3 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="subject"
            disabled={currentSlide !== 3}
            value="other"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Outro</span>
        </S.RadioLabel>
      </S.InputContainer>
    </Question>,

    <Question
      isCurrent={currentSlide === 4}
      text={
        <label htmlFor="urgency">
          quase lá. qual a urgência deste projeto?
        </label>
      }
      handleBackButton={handlePrevSlide}
      handleContinueButton={handleNextSlide}
      isNextButtonDisabled={isNextButtonDisabled(formAnswers, 'urgency')}
    >
      <S.InputContainer>
        <S.RadioLabel
          tabIndex={currentSlide === 4 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="urgency"
            disabled={currentSlide !== 4}
            value="high"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>É pra ontem!</span>
        </S.RadioLabel>

        <S.RadioLabel
          tabIndex={currentSlide === 4 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="urgency"
            disabled={currentSlide !== 4}
            value="medium"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Média, meu prazo é flexível</span>
        </S.RadioLabel>

        <S.RadioLabel
          tabIndex={currentSlide === 4 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="urgency"
            disabled={currentSlide !== 4}
            value="low"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Baixa, tempo não é problema</span>
        </S.RadioLabel>
      </S.InputContainer>
    </Question>,

    <Question
      isCurrent={currentSlide === 5}
      text={
        <label htmlFor="type">
          desafiador! última pergunta: qual é o tipo de projeto?
        </label>
      }
      handleBackButton={handlePrevSlide}
      handleContinueButton={handleNextSlide}
      isNextButtonDisabled={isNextButtonDisabled(formAnswers, 'type')}
      isLastSlide
    >
      <S.InputContainer>
        <S.RadioLabel
          tabIndex={currentSlide === 5 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="type"
            disabled={currentSlide !== 5}
            value="website"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Site</span>
        </S.RadioLabel>

        <S.RadioLabel
          tabIndex={currentSlide === 5 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="type"
            disabled={currentSlide !== 5}
            value="app"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Aplicativo</span>
        </S.RadioLabel>

        <S.RadioLabel
          tabIndex={currentSlide === 5 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="type"
            disabled={currentSlide !== 5}
            value="websystem"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Sistema web</span>
        </S.RadioLabel>

        <S.RadioLabel
          tabIndex={currentSlide === 5 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="type"
            disabled={currentSlide !== 5}
            value="other"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Outro</span>
        </S.RadioLabel>

        <S.RadioLabel
          tabIndex={currentSlide === 5 ? '0' : '-1'}
          onKeyPress={handleRadioEnterPress}
        >
          <input
            type="radio"
            name="type"
            disabled={currentSlide !== 5}
            value="help"
            onChange={handleInputChange}
          />
          <S.RadioCheckmark type="button" tabIndex="-1" />
          <span>Não tenho certeza, preciso de ajuda…</span>
        </S.RadioLabel>
      </S.InputContainer>
    </Question>,
  ];

  const slidesLength = slides.length;
  const slideTransitionDuration = '0.5s';

  return (
    <Layout>
      <Seo title="Fale conosco" />

      {!isFormSent && (
        <S.FormContainer>
          <Progress
            slidesLength={slidesLength}
            slideTransitionDuration={slideTransitionDuration}
            currentSlide={currentSlide}
          />

          <form onSubmit={(e) => handleSubmit(e)}>
            <Carousel
              slides={slides}
              slideTransitionDuration={slideTransitionDuration}
              currentSlide={currentSlide}
            />
          </form>
        </S.FormContainer>
      )}

      {isFormSent && (
        <FormSent name={capitalizeFirstLetter(formAnswers.name.value)} />
      )}
    </Layout>
  );
};

const S = {
  FormContainer: styled.div``,

  InputContainer: styled.div`
    margin-top: 16px;
    max-width: 502px;

    &:first-of-type {
      margin-top: 24px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 48px;
      }
    }
  `,

  RadioLabel: styled.label`
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-secondary, #37474f);
    user-select: none;
    cursor: pointer;

    &:not(:first-of-type) {
      margin-top: 16px;
    }

    & > input {
      display: none;
    }

    & > span {
      flex: 1;
      transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

      &:hover {
        opacity: var(--hover-opacity, 0.8);
      }
    }
  `,

  RadioCheckmark: styled.button`
    margin-right: 16px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 2px solid var(--color-primary, #3f51b5);
    pointer-events: none;
    transition: border 0.25s ease-in-out;

    input:checked ~ & {
      border: 8px solid var(--color-primary, #3f51b5);
    }
  `,
};

export default Contact;
