import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import CenteredPageContainer from '../../../common/CenteredPageContainer/CenteredPageContainer';

import backgroundImage from './images/form-sent-background.svg';

const FormSent = ({ name = '' }) => (
  <CenteredPageContainer>
    <S.BackgroundImage
      src={backgroundImage}
      alt="Representação de alguns elementos utilizados na construção de um website"
    />
    <S.InfoContainer>
      <S.Title>{name ? `${name}, ` : ''}agradecemos pela confiança.</S.Title>

      <S.Description>
        Seu formulário foi enviado com sucesso. Logo entraremos em contato
        através do email que você nos forneceu.
      </S.Description>

      <S.Description>
        Caso tenha qualquer outra dúvida, envie um email para{' '}
        <a href="mailto:contato@gowebit.com.br">contato@gowebit.com.br</a>
      </S.Description>

      <S.Description>Até breve!</S.Description>

      <S.StyledLink to="/">voltar para a home</S.StyledLink>
    </S.InfoContainer>
  </CenteredPageContainer>
);

const S = {
  BackgroundImage: styled.img`
    width: 205px;
    height: 134.16px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: -48px;
      margin-right: 32px;
      width: 373px;
      height: 244.11px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-right: 0;
    }
  `,

  InfoContainer: styled.div`
    margin-top: 28px;
    max-width: 296px;
    color: var(--color-secondary, #37474f);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 0;
      max-width: 413px;
    }
  `,

  Title: styled.h1`
    max-width: 261px;
    font-size: 24px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 29.9px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 432px;
      font-size: 40px;
      line-height: 44px;
    }
  `,

  Description: styled.p`
    margin-top: 24px;
    max-width: 295px;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-text, #7f7f7f);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 413px;

      &:not(:first-of-type) {
        margin-top: 16px;
      }
    }

    & > a {
      color: var(--color-text-primary, #3f51b5);

      &:hover {
        opacity: var(--hover-opacity, 0.8);
      }
    }
  `,

  StyledLink: styled(Link)`
    margin-top: 24px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 12px;
    letter-spacing: 0.48px;
    color: var(--color-text-highlight, #ffffff);
    text-transform: uppercase;
    background-color: var(--color-text-primary, #3f51b5);
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 32px;
      width: 324px;
    }

    &:hover {
      opacity: var(--hover-opacity, 0.8);
    }
  `,
};

FormSent.propTypes = {
  name: PropTypes.string,
};

export default FormSent;
