import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Question = ({
  children,
  isCurrent,
  text,
  handleBackButton,
  handleContinueButton,
  isNextButtonDisabled,
  isFirstSlide = false,
  isLastSlide = false,
}) => (
  <S.Container>
    <S.Text>{text}</S.Text>

    {children}

    <S.ButtonsContainer>
      <S.ButtonPrevious
        type="button"
        onClick={() => handleBackButton(isFirstSlide)}
        disabled={!isCurrent}
        $isFirstSlide={isFirstSlide}
      >
        voltar
      </S.ButtonPrevious>

      <S.ButtonNext
        type={isLastSlide ? 'submit' : 'button'}
        onClick={() => handleContinueButton(isLastSlide)}
        disabled={!isCurrent || isNextButtonDisabled}
        $isFirstSlide={isFirstSlide}
        $isLastSlide={isLastSlide}
      >
        {isLastSlide ? 'enviar' : 'continuar'}
      </S.ButtonNext>
    </S.ButtonsContainer>

    {isLastSlide && (
      <S.GrecaptchaText>
        Este site é protegido pelo reCAPTCHA e são aplicáveis a{' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={isCurrent ? '0' : '-1'}
        >
          Política de Privacidade
        </a>{' '}
        e os{' '}
        <a
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={isCurrent ? '0' : '-1'}
        >
          Termos de Serviço
        </a>{' '}
        do Google.
      </S.GrecaptchaText>
    )}
  </S.Container>
);

const S = {
  Container: styled.div`
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 32px;
    padding: var(--grid-padding, 0 32px);
    max-width: var(--grid-width, 1100px);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 64px;
    }
  `,

  Text: styled.h1`
    max-width: 592px;
    font-size: 24px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 29.9px;
    color: var(--color-secondary, #37474f);

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 40px;
      line-height: 44px;
    }
  `,

  ButtonsContainer: styled.div`
    margin-top: 32px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-top: 32px;
    }
  `,

  ButtonPrevious: styled.button`
    margin-right: 16px;
    width: 88px;
    height: 48px;
    font-size: 16px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 12px;
    letter-spacing: 0.48px;
    color: var(--color-primary, #3f51b5);
    text-transform: uppercase;
    background-color: #ffffff;
    border: 2px solid var(--color-primary, #3f51b5);
    cursor: pointer;
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    &:hover {
      opacity: var(--hover-opacity, 0.8);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xxs}) {
      width: 104px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-right: 24px;
      width: 178px;
    }

    ${({ $isFirstSlide }) =>
      $isFirstSlide &&
      css`
        display: none;
      `}
  `,

  ButtonNext: styled.button`
    width: 152px;
    height: 48px;
    font-size: 16px;
    font-weight: var(--font-weight-semi-bold, 600);
    line-height: 12px;
    letter-spacing: 0.48px;
    color: var(--color-text-highlight, #ffffff);
    text-transform: uppercase;
    background-color: var(--color-primary, #3f51b5);
    cursor: pointer;
    transition: var(--hover-opacity-transition, opacity 0.25s ease-in-out);

    &:hover:not(:disabled) {
      opacity: var(--hover-opacity, 0.8);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xxs}) {
      width: 176px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 300px;
    }

    ${({ $isFirstSlide }) =>
      $isFirstSlide &&
      css`
        width: 100%;
        max-width: 502px;
      `}
  `,

  GrecaptchaText: styled.p`
    margin-top: 24px;
    max-width: 296px;
    font-size: 12px;
    line-height: 18px;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      max-width: 502px;
    }

    & > a {
      text-decoration: underline;
    }
  `,
};

Question.propTypes = {
  children: PropTypes.node.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  text: PropTypes.node.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  handleContinueButton: PropTypes.func.isRequired,
  isNextButtonDisabled: PropTypes.bool.isRequired,
  isFirstSlide: PropTypes.bool,
  isLastSlide: PropTypes.bool,
};

export default Question;
